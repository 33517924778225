<template>
  <div class="height100">
    <a-layout class="height100">
      <template #renderEmpty>
        <a-empty :image="require('@/assets/empty/global-empty.png')"> </a-empty>
      </template>
      <a-layout-content :style="{ background: '#F4F5F7' }">
        <micro-app class="micro-height" :name="nick_name" :url="url" :data="dataForChild" baseroute="/search-result"
          @created="handleCreate" @beforemount="handleBeforeMount" @mounted="handleMount" @unmount="handleUnmount"
          @error="handleError" @datachange="handleDataChange"></micro-app>
      </a-layout-content>
    </a-layout>
  </div>
</template>

<script>
  import microApp from '@micro-zoe/micro-app'
  import storage from 'store'
  export default {
    inject: ['reload'],
    name: 'BlankLayout',
    data() {
      return {
        nick_name: 'sousuo',
         url: process.env.VUE_APP_API_BASE_URL != 'https://sandbox.interface.meirenji.vip' ? 'https://sg.shenglisige.com/' : 'https://sandbox.base.meirenji.vip/',
        url_name: '/search-result',
        brand_ob: '',
        dataForChild: {},

      }
    },
    created() {
     //debugger
      storage.set('microApp', 'sousuo')
      microApp.start()

    },
    mounted() {},
    methods: {
      handleCreate() {
        console.log(this.nick_name + '创建了')
      },

      handleBeforeMount() {
        console.log(this.nick_name + ' 即将被渲染')
       microApp.setData(this.nick_name, {
          path: '/search-result',
          query: {
            nick_name: this.nick_name,
            url: this.url,
            url_name: '/search-result',
            keyworeds: this.$route.query.keyworeds,
            sysName: '总部'
          },
        })
      },

      handleMount() {
       // debugger
        console.log(this.nick_name + '已经渲染完成')
      },

      handleUnmount() {
        console.log(this.nick_name + '卸载了')
      },

      handleError() {
        console.log(this.nick_name + '加载出错了')
      },

      handleDataChange(e) {
        //监听子系统的数据
        if (e.detail.data.url) { //如果是url 就进行跳转
         location.href = `${window.location.origin}${e.detail.data.url}`
/*          this.$router.replace({
            path: e.detail.data.url
          }) */
        }

      },
    },
  }
</script>

<style lang="less" scoped>

</style>
